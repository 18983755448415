<template>
  <div>
    <MainLayout>
      <div
        v-if="!loading"
        :style="{
          background: 'url(' + registerBackground + ')',
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
          backgroundAttachment: 'fixed',
          height: '100vh',
          overflow: 'auto',
        }"
      >
        <div style="text-align: center; display: flex;justify-content: center;align-items: center;">
          <div style="width: 100%">
            <img src="@/assets/head_logo.png" width="100%" />
          </div>
        </div>
        <div class="px-7 pb-7">
          <ValidationObserver ref="observer">
            <ValidationProvider
              :rules="{
                required: true,
              }"
              name="ชื่อจริง"
              v-slot="{ errors }"
            >
              <custom-input v-model="input.firstName" prepend-inner-icon="mdi-account" placeholder="ชื่อจริง" rounded solo dense outlined :error-messages="errors"></custom-input>
            </ValidationProvider>
            <ValidationProvider
              :rules="{
                required: true,
              }"
              name="นามสกุล"
              v-slot="{ errors }"
            >
              <custom-input v-model="input.lastName" prepend-inner-icon="mdi-account" placeholder="นามสกุล" rounded solo dense outlined :error-messages="errors"></custom-input>
            </ValidationProvider>
            <ValidationProvider
              :rules="{
                required: true,
                thai_phonenumber: true,
                digits: 10,
                regex: /^[0][3-9][0-9]{4}[0-9]{4}$/,
              }"
              name="เบอร์โทรศัพท์"
              v-slot="{ errors }"
            >
              <custom-input placeholder="เบอร์โทร" type="tel" v-model="input.phone" maxlength="10" dense rounded solo prepend-inner-icon="mdi-phone-in-talk-outline" numberOnly outlined :error-messages="errors"></custom-input>
            </ValidationProvider>
            <ValidationProvider
              :rules="{
                required: true,
              }"
              name="อาชีพ"
              v-slot="{ errors }"
            >
              <custom-input placeholder="อาชีพ" v-model="input.job" dense rounded solo prepend-inner-icon="mdi-account" outlined :error-messages="errors"></custom-input>
            </ValidationProvider>
          </ValidationObserver>

          <v-card class="pa-4" elevation="11" rounded="lg">
            <v-checkbox v-model="input.term" color="primary" hide-details style="margin-top: 0px !important">
              <template v-slot:label> <span>ยอมรับ</span>&nbsp;<span @click="$router.push({ name: 'conditionAndTerm' })" style="color: red">เงื่อนไขและข้อตกลง</span> </template>
            </v-checkbox>
            <v-checkbox v-model="input.privacy" hide-details style="margin-top: 0px !important">
              <template v-slot:label> <span>ยอมรับ</span>&nbsp;<span @click="$router.push({ name: 'privacyPolicy' })" style="color: red">นโยบายความเป็นส่วนตัว</span> </template>
            </v-checkbox>
            <div class="red--text mt-2" style="font-size: 14px; cursor: pointer;" @click="$router.push({ name: 'readPrivacyPolicy' })">
              <u>อ่านนโยบายความเป็นส่วนตัว</u>
            </div>
            <div style="color: red; padding-top: 15px" v-if="DontAccept">
              *โปรดยอมรับเงื่อนไขทั้ง 2 ข้อด้านบน
            </div>
          </v-card>

          <div class="text-center">
            <img @click="onSubmit" src="@/assets/btn.png" class="mt-1" width="200px" height="65px" />
          </div>
        </div>
        <v-dialog v-model="showDialog" width="450px" height="450px">
          <v-card>
            <div style="display: flex; justify-content: center; text-align: center">
              <div style="width: 350px; margin: 30px">
                <v-icon color="primary" large class="mb-4" style="font-size: 95px">mdi-check-circle</v-icon>
                <h3 class="mb-4">ลงทะเบียนสำเร็จ</h3>
                <v-btn color="main-color" class="mb-2 white--text" rounded block large @click="$router.push({ name: 'uploadReceipt' })">แนบใบเสร็จ</v-btn>
                <v-btn color="gray" block @click="onClose" rounded large>ปิด</v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog v-model="showDuplicateDialog" width="450px" height="450px">
          <v-card>
            <div style="display: flex; justify-content: center; text-align: center">
              <div style="width: 350px; margin: 30px">
                <v-icon color="primary" large class="mb-4" style="font-size: 95px">mdi-alert-circle</v-icon>
                <h3 class="mb-4">{{ regisErrorMessage || "พบข้อมูลซ้ำ" }}</h3>
                <v-btn color="main-color" class="mb-2 white--text" block @click="onClose" rounded large>ปิด</v-btn>
              </div>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </MainLayout>
  </div>
</template>

<script>
import api from "@/api";
import { member } from "../api/member";
export default {
  data() {
    return {
      showDialog: false,
      DontAccept: false,
      showDuplicateDialog: false,
      regisErrorMessage: "พบข้อมูลซ้ำ",
      registerBackground: require("../assets/toabgnew.png"),
      loading: false,
    };
  },
  computed: {
    input() {
      return this.$store.state.register;
    },
  },
  async mounted() {
    window.scrollTo(0, 0);
    // await this.getBackground();
    await this.getProfile();
  },
  methods: {
    async getProfile() {
      try {
        this.loading = true;
        this.$store.commit("setLoading", true);
        let user_id = this.$store.state.userId;
        let page_id = this.$store.state.pageId;
        let IsValid = await api.getProfile({ user_id, page_id });
        if (IsValid) {
          this.$router.push({ name: "profile" });
          // this.$liff.closeWindow();
        } else {
          this.$store.commit("setLoading", false);
          this.loading = false;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit("setLoading", false);
        this.loading = false;
      }
    },
    async onSubmit() {
      let IsValid = await this.$refs.observer.validate();
      let PDDAValid = this.input.term && this.input.privacy;
      if (IsValid && PDDAValid) {
        this.$store.commit("setLoading", true);
        this.regisErrorMessage = null;
        let payload = {
          ...this.input,
          user_id: this.userId,
          page_id: this.pageId,
        };
        payload.first_name = this.input.firstName;
        payload.last_name = this.input.lastName;
        payload.agree_terms_conditions = this.input.term;
        payload.agree_privacy_policy = this.input.privacy;
        try {
          let result = await member.register(payload);
          this.showDialog = true;
          this.$store.commit("setUserData", result.data.data);
          this.$store.dispatch("sendMessage", "ลงทะเบียนสำเร็จ");
        } catch (error) {
          this.showDuplicateDialog = true;
          this.regisErrorMessage = error?.response?.data?.message || null;
        } finally {
          this.$store.commit("setLoading", false);
        }
      } else {
        if (!PDDAValid) {
          this.DontAccept = true;
        }
      }
    },
    onClose() {
      this.showDialog = false;
      this.showDuplicateDialog = false;
      this.$liff.closeWindow();
    },
    async getBackground() {
      this.$store.commit("setLoading", true);
      this.loading = true;
      let data = {
        pageid: this.pageId,
        source: "line",
      };
      let result = await api.getRegisterBackground(data);
      if (result.responsecode == 200) {
        this.loading = false;
        this.$store.commit("setLoading", false);
        if (result.data && result.data.image) {
          this.registerBackground = result.data.image;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// @media screen and (max-width: 400px) {
//   .input-box{
//     padding: 320px 28px 28px 28px
//   }
// }

// @media screen and (min-width: 401px) {
//   .input-box{
//     padding: 300px 28px 28px 28px
//   }
// }
::v-deep .v-messages.error--text {
  color: red !important;
}
</style>
